import React, { useRef, useState, useEffect } from "react";

import "./WindowsOurLatestWork.scss";
import Slider from "react-slick";


import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LeftBtn from "../../Assets/images/latestwork-left-btn.png";
import RightBtn from "../../Assets/images/latestwork-right-btn.png";

import img1 from "../../Assets/images/lclc11.jpeg";
import img2 from "../../Assets/images/lclc2.jpeg";
import img3 from "../../Assets/images/lclc3.jpeg";
import img4 from "../../Assets/images/lclc4.jpeg";
import img5 from "../../Assets/images/lclc5.jpeg";
import img6 from "../../Assets/images/lclc6.jpg";

const listingImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6
];

const handler = {};

const useStyles = makeStyles({
    rootD: {
      ['& .MuiPaper-root']: {
          overflow: 'visible',
      }
    },
    customIconCloseChat: {
      color: '#19ace4',
      background: '#fff',
      borderRadius: '50%',
        cursor: "pointer",
        position: "absolute",
        right: '2px',
        top: "2px",
        width: '38px',
        height: '38px',
        margin: '-22px -22px 0 0',
    },
    customIconLeft: {
      color: '#19ace4',
      background: '#fff',
      borderRadius: '50%',
        cursor: "pointer",
        position: "absolute",
        left: '-40px',
        top: "50%",
        width: '38px',
        height: '38px',
        margin: '-22px -22px 0 0',
    },
    customIconRight: {
      color: '#19ace4',
      background: '#fff',
      borderRadius: '50%',
        cursor: "pointer",
        position: "absolute",
        right: '-18px',
        top: "50%",
        width: '38px',
        height: '38px',
        margin: '-22px -22px 0 0',
    },
});
const sliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  infinite: true,
  centerPadding: "5px",
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const WindowsOurListing = ({
  title,
  hideButton,
  subtitle,
  noTitle,
  noButton,
  handler,
  children
}) => {

  const additionalSliderSettings = {
    initialSlide: 0,
    autoplay: true,
    focusOnSelect: true,
  };
  const sliderRef = useRef();
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };
  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="ourLatestWork">

      <div className="sliderContainer">
        <div className="arrowLeft" style={{ cursor: 'pointer', left: 'auto' }} onClick={prevSlide}>
          <img src={LeftBtn} />
        </div>
        <div style={{ maxWidth: '800px' }} className="testimonial-slider">
          <Slider
            {...sliderSettings}
            {...additionalSliderSettings}
            ref={sliderRef}
          >
            {listingImages ? listingImages.map((item, index, items) => (
              <div className="box" key={index} onClick={()=>{ 
                handler.updateState({ contentImage: items[index] }); 
                handler.index = index;
                handler.items = items;
              }}>
                <img className="lcimg" style={{borderRadius: '0px',cursor: 'pointer',  }} src={item} />
              </div>
            )) : null}
          </Slider>
        </div>
        <div className="arrowRight" style={{ cursor: 'pointer', right: 'auto' }} onClick={nextSlide}>
          <img src={RightBtn} />
        </div>
      </div>

      {children}
      
    </section>
  );
};

const Modal = (props) => {
 
    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);

    const [isOpenVideo, setOpenVideo] = useState(false);
    const [image, setImage] = useState('');
    const [scrollY, setScrollY] = useState(0);

    const handleClose = () => {
      setOpen(false); 
      setTimeout( e => { window.scrollTo({ top: scrollY }) }, 220);
    };
    const handleNext = () => {
      const { index, items } = props.handler;
      const newIndex = index === items.length-1 ? 0 : index+1;
      props.handler.index = newIndex;
      setImage(items[newIndex]);
    };
    const handlePrev = () => {
      const { index, items } = props.handler;
      const newIndex = index === 0 ? items.length-1 : index-1;
      props.handler.index = newIndex;
      setImage(items[newIndex]);
    };

    if (props && props.handler) {
        props.handler.updateState = ({ contentImage }) => {
            setScrollY(window.scrollY);
            setOpen(!isOpen);
            setImage(contentImage);
        };
        props.handler.updateStateVideo = () => {
          setOpenVideo(!isOpenVideo);
        };
    }

    return (
      <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth={true}
        className={classes.rootD}
      >
        <DialogContent >
            <img src={image} />
        </DialogContent>
        <HighlightOffIcon className={classes.customIconCloseChat} onClick={handleClose}/>

        <ChevronLeftIcon className={classes.customIconLeft} onClick={handlePrev}/>
        <ChevronRightIcon className={classes.customIconRight} onClick={handleNext}/>

      </Dialog>


      </>
    );
};

export const Licences = () => {
 return (
    <WindowsOurListing
        title=""
        subtitle=""
        noTitle={true}
        hideButton={true}
        handler={handler}
    >
        <Modal handler={handler} />
    </WindowsOurListing>
 )
};